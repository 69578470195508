module.exports = () => {
  return `
  ... on WordPress_Page_Components_Contents_IndividualCategory {
    fieldGroupName

    title    
    description
    individualCategoryImage {
      title
      altText                
      sourceUrl
      mediaDetails {
        height
        width
      }
    }
    category {
      id
      name
      slug
      uri
      description
    }
  }
  `;
};
