module.exports = () => {
    return `
      ... on WordPress_Post_Components_Contents_ImageSubnav {
        fieldGroupName
        imageSubnav {
          fieldGroupName
          article {
            ... on WordPress_Post {
                id
                uri
                slug
                subNavigationImage {
                  fieldGroupName
                  subNavigationImage {
                    altText
                    sourceUrl
                    mediaDetails {
                      height
                      width
                    }
                  }
                  subNavigationImageTitle
                }
            }
          }
        }
        imageSubnavAnimationDelayActiveSlide
        imageSubnavAnimationDelayInactiveSlide
        imageSubnavAnimationSpeed
        imageSubnavStyle
      }
    `
}
