module.exports = () => {
  return `
    ... on WordPress_Post_Components_Contents_LargePhoto {
      cutline
      fieldGroupName
      floatingBodyText
      floatingTextPosition
      floatingTextMobilePosition
      floatingTextColor
      floatingTextAlign
      floatingTextStyle
      floatingTextNowrap
      fullScreen
      image {
        title
        altText
        sourceUrl
        mediaDetails {
          height
          width
        }
      }
      popup {
        fieldGroupName
        headline
        popupCopy
      }
    }
  `;
};
