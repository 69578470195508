module.exports = () => {
  return `
    ... on WordPress_Post_Components_Contents_Spacer {
      fieldGroupName
      spacer
      spacerBackground
      visibility
    }
  `
}
