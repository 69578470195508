import React, { useEffect, useState } from "react";

import Post from 'src/templates/post'

import replaceAmpersand from 'src/utils/replaceAmpersand';
import Seo from 'src/components/seo';

import Placeholder from './Placeholder'

import PostHero           from "src/components/PostHero/PostHero.postData.js"
import PostHeroAlt        from "src/components/PostHeroAlt/PostHeroAlt.postData.js"
import Quote              from "src/components/Quote/Quote.postData.js"
import CopyColumns        from "src/components/CopyColumns/CopyColumns.postData.js"
import PhotoBackground    from "src/components/PhotoBackground/PhotoBackground.postData.js"
import PhotoLayout        from "src/components/PhotoLayout/PhotoLayout.postData.js"
import FiftyFifty         from "src/components/FiftyFifty/FiftyFifty.postData.js"
import PhotoLayout1X3     from "src/components/PhotoLayout1X3/PhotoLayout1X3.postData.js"
import TastingNotes       from "src/components/TastingNotes/TastingNotes.postData.js"
import Spacer             from "src/components/Spacers/Spacer.postData.js"
import ColumnCopy         from "src/components/ColumnCopy/ColumnCopy.postData.js"
import Carousel           from "src/components/Carousel/Carousel.postData.js"
import ContentSlider      from "src/components/ContentSlider/ContentSlider.postData.preview.js"
import YouTubeVideo       from "src/components/YouTubeVideo/YouTubeVideo.postData.js"
import FullwidthCopy      from "src/components/FullwidthCopy/FullwidthCopy.postData.js"
import FullscreenPhoto    from "src/components/FullscreenPhoto/FullscreenPhoto.postData.js"
import BrandLogo          from "src/components/BrandLogo/BrandLogo.postData.js"
import ImageSubnav        from "src/components/ImageSubnav/ImageSubnav.postData.js"


const PreviewPost = ({ postId }) => {

  const [pageContext,setPageContext] = useState( false )
  const [errors,setErrors] = useState( null )


  useEffect( () => {
    let layouts = [
      PostHero(),
      PostHeroAlt(),
      Quote(),
      CopyColumns(),
      PhotoBackground(),
      PhotoLayout(),
      FiftyFifty(),
      PhotoLayout1X3(),
      TastingNotes(),
      Spacer(),
      ColumnCopy(),
      Carousel(),
      YouTubeVideo(),
      FullwidthCopy(),
      FullscreenPhoto(),
      BrandLogo(),
      ImageSubnav(),
    ]
  
    layouts = layouts.map( item => item.replaceAll('WordPress_Post', 'Post').replaceAll('WordPress_Page','Page') )
  
    // add ContentSlider after all, since it includes all
    let contentSliderLayouts = layouts.map( l => l.replace('Post_Components_Contents_', 'Post_Components_Contents_ContentSlider_contentSlider_Contents_') )
    layouts.push( ContentSlider( contentSliderLayouts.join('\n') ) )

    const query = `
      query {
        postBy(postId: ${postId}) {
          id
          title
          slug
          uri
          date
          dateGmt
          modified
          modifiedGmt   
          author {
            id
            name
          }
          language {
            code
            slug
          }
          translations {
            id
            title
            slug
            uri
            language {
              code
              slug
            }
          }
          themeSelect {
            fieldGroupName
            themeSelect
          }
          categories {
            nodes {
              name
            }
          }
          components {
            contents {
              ${layouts.join('\n')}
            }
          }
          seo {
            schema
            headers {
              content
              field
              value
            }
          } 
        }
      }
    `

    fetch(process.env.GATSBY_WP_GRAPHQL_ENDPOINT, { 
      method: "POST",
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify( { query } ) 
    })
    .then( response => response.json() )
    .then( postData => {
      if( postData.errors ) {
        setErrors( postData.errors )
        console.log( query )
        console.log( postData.errors )
        return
      }

      if( postData.data && postData.data.postBy ) {
        setPageContext( postData.data.postBy ) 
      }
      else {
        setPageContext( null )
      }
    })

  }, [postId])




  if( !pageContext ) {
    return <Placeholder { ...{ pageContext, postId, errors } } />
  }
  

  const { title } = pageContext;
  const postHeroObj = pageContext.components.contents.find(c => c.fieldGroupName === "post_Components_Contents_ArticleHero");
    
  return (
    <div>
      <Seo
        title={replaceAmpersand(title) || "Untitled"}
        description={postHeroObj && postHeroObj.byline}
        imageUrl={postHeroObj && ( ( postHeroObj.socialHeroImage && postHeroObj.socialHeroImage.sourceUrl ) || ( postHeroObj.heroImage && postHeroObj.heroImage.sourceUrl ) )}
        articleUrl={pageContext.url}
        articleLang={pageContext.language.slug}
        pageContext={pageContext}
        type="article"
      />
      <Post pageContext={ pageContext } />

    </div>
  )
}

export default PreviewPost

