import React from "react";

const PreviewPlaceholder = ({ postId, pageContext, errors }) => {

  const containerStyles = { 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '80vh',
  }

  let message


  if( null === pageContext ) {
    message = <p>Post {postId} not found</p>
  }
  else if( !pageContext ) {
    if( postId ) {
      message = <p>Preparing preview of #{ postId }</p>
    }
    else {
      message = <p>ID was not specified for preview</p>
    }
  }
  

  return (
    <div style={ containerStyles }>
      { message }

      { 
        errors && errors.length 
          ? (
            <div>
              <h2>Errors:</h2>
              
              { errors.map( (err,idx) => <div style={{ color: 'red' }} key={idx}>{ err.message }</div> ) }
            </div>
            ) 
          : "" 
      }
    </div>
  )

}

export default PreviewPlaceholder

