module.exports = (layouts) => {
    return `
      ... on Post_Components_Contents_ContentSlider {
        fieldGroupName
        contentSlider {
          contentSliderHeadline
          contentSliderSecondaryHeadline
          fieldGroupName
          contents {
            ${layouts}
          }
        }
      }
    `
}
