module.exports = () => {
  return `
    ... on WordPress_Post_Components_Contents_FiftyFifty {
      copyBlock
      fieldGroupName
      headline
      image {
        title
        altText
        sourceUrl
        mediaDetails {
          height
          width
        }
      }
      reverse
      copyBackgroundColor
      copyColor
      textAlign
    }
  `
}
