module.exports = () => {
  return `
  ... on WordPress_Page_Components_Contents_FullscreenArticle {
    fieldGroupName
    fullScreenArticleImage {
      title
      altText
      sourceUrl
      mediaDetails {
        height
        width
      }      
    }
    fullScreenArticleMobileImage {
      title
      altText
      sourceUrl
      mediaDetails {
        height
        width
      }      
    }
    articleInfoPosition
    article {
      ... on WordPress_Post {
        id
        uri
        components {
          contents {
            ... on WordPress_Post_Components_Contents_ArticleHero {
              authors
              byline
              title
              heroImage {
                sourceUrl
                mediaDetails {
                  height
                  width
                }                
              }
              fieldGroupName
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
  `;
};
