module.exports = () => {
  return `
    ... on WordPress_Page_Components_Contents_FullwidthCopy {
      fieldGroupName
      headline
      copy
      copyBackgroundColor
      copyColor
      copyBottomSpacer
    }
  `;
};
