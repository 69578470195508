import React, { useEffect, useState } from "react";

import Page from 'src/templates/page'

import replaceAmpersand from 'src/utils/replaceAmpersand';
import Seo from 'src/components/seo';

import Placeholder from './Placeholder'



import TopArticlesHome      from "src/components/TopArticlesHome/TopArticlesHome.pageData.js"
import PageHero             from "src/components/PageHero/PageHero.pageData.js"
import FullscreenArticle    from "src/components/FullscreenArticle/FullscreenArticle.pageData.js"
import Chefs                from "src/components/Chefs/Chefs.pageData.js"
import SociallyConnected    from "src/components/SociallyConnected/SociallyConnected.pageData.js"
import IndividualArticle    from "src/components/IndividualArticle/IndividualArticle.pageData.js"
import IndividualCategory   from "src/components/IndividualCategory/IndividualCategory.pageData.js"
import Ad                   from "src/components/Ad/Ad.pageData.js"
import TastingNotes         from "src/components/TastingNotes/TastingNotes.pageData.js"
import Spacer               from "src/components/Spacers/Spacer.pageData.js"
import EventsComponent      from "src/components/EventsComponent/EventsComponent.pageData.js"
// import Carousel             from "src/components/Carousel/Carousel.pageData.js"
// import ImageSubnav          from "src/components/ImageSubnav/ImageSubnav.pageData.js"
import YouTubeVideo         from "src/components/YouTubeVideo/YouTubeVideo.pageData.js"
import FullscreenPhoto      from "src/components/FullscreenPhoto/FullscreenPhoto.pageData.js"
// import BrandLogo            from "src/components/BrandLogo/BrandLogo.pageData.js"
import FullwidthCopy        from "src/components/FullwidthCopy/FullwidthCopy.pageData.js"
import DestionationBanner   from "src/components/DestionationBanner/DestionationBanner.pageData.js"
import DestionationSubNavigation from "src/components/DestionationSubNavigation/DestionationSubNavigation.pageData.js"
import DestinationIntroduction from "src/components/DestinationIntroduction/DestinationIntroduction.pageData.js"
import ColumnCopy           from "src/components/ColumnCopy/ColumnCopy.pageData.js"

import AboutTitle           from 'src/components/AboutComponents/AboutTitle/AboutTitle.aboutPageData.js'
import AboutMain            from 'src/components/AboutComponents/AboutMain/AboutMain.aboutPageData.js'
import AboutColumns         from 'src/components/AboutComponents/AboutColumns/AboutColumns.aboutPageData.js'

import EventsPageComponent   from "src/components/EventsPageComponent/EventsPageComponent.eventPageData.js"


const PreviewPage = ({ postId }) => {

  const [pageContext,setPageContext] = useState( false )
  const [errors,setErrors] = useState( null )


  useEffect( () => {
    let layouts = [
      TopArticlesHome(),
      PageHero(),
      FullscreenArticle(),
      Chefs(),
      SociallyConnected(),
      IndividualArticle(),
      IndividualCategory(),
      Ad(),
      TastingNotes(),
      Spacer(),
      EventsComponent(),
      // Carousel(),
      // ImageSubnav(),
      YouTubeVideo(),
      FullscreenPhoto(),
      // BrandLogo(),
      FullwidthCopy(),
      DestionationBanner(),
      DestionationSubNavigation(),
      DestinationIntroduction(),
      ColumnCopy(),      
    ]
    let aboutLayouts = [
      AboutTitle(),
      AboutMain(),
      AboutColumns(),      
    ]
    let eventLayouts = [
      EventsPageComponent()
    ]
  
    layouts = layouts.map( item => item.replaceAll('WordPress_Post', 'Post').replaceAll('WordPress_Page','Page') )
    aboutLayouts = aboutLayouts.map( item => item.replaceAll('WordPress_Post', 'Post').replaceAll('WordPress_Page','Page') )
    eventLayouts = eventLayouts.map( item => item.replaceAll('WordPress_Post', 'Post').replaceAll('WordPress_Page','Page') )
  
    const query = `
      query {
        pageBy(pageId: ${postId}) {
          id
          title
          slug
          uri
          isFrontPage
          date
          dateGmt
          modified
          modifiedGmt
          author {
            id
            name
          }
          language {
            code
            slug
          }
          translations {
            id
            title
            slug
            uri
            language {
              code
              slug
            }
            components {
              contents {
                ${layouts}
              }
              aboutContents {
                ${aboutLayouts}
              }
              eventContents {
                ${eventLayouts}
              }
            }
          }
          components {
            contents {
              ${layouts}
            }
            aboutContents {
              ${aboutLayouts}
            }
            eventContents {
              ${eventLayouts}
            }
          }
          seo {
            schema
            headers {
              content
              field
              value
            }
          }  
        }
      }
    `

    fetch(process.env.GATSBY_WP_GRAPHQL_ENDPOINT, { 
      method: "POST",
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify( { query } ) 
    })
    .then( response => response.json() )
    .then( postData => {
      if( postData.errors ) {
        setErrors( postData.errors )
        console.log( query )
        console.log( postData.errors )
        return
      }

      if( postData.data && postData.data.pageBy ) {
        setPageContext( postData.data.pageBy ) 
      }
      else {
        setPageContext( null )
      }
    })

  }, [postId])




  if( !pageContext ) {
    return <Placeholder { ...{ pageContext, postId, errors } } />
  }
  

  const { title } = pageContext;
  const heroData = pageContext.components.contents.filter(
    (o) => o.fieldGroupName === 'page_Components_Contents_HomepageHero'
  );
  const destionationsData = pageContext.components.contents.filter(o => o.fieldGroupName === 'page_Components_Contents_DestinationBanner');
  const isDestionationPage = destionationsData && destionationsData.length > 0;
  const destionation = destionationsData && destionationsData.length > 0 && destionationsData[0];
  const pageUri = pageContext.uri;


  let SeoObject 
  /* Front Page */
  if (pageContext.isFrontPage) {
    SeoObject = <Seo
          title={title || 'Untitled'}
          imageUrl={heroData && heroData.length && heroData[0] && heroData[0].socialImage && heroData[0].socialImage.sourceUrl}
          type="frontPage"
          pageContext={pageContext}
        />
  }
  else {
    SeoObject = <Seo
      title={title || 'Untitled'}
      articleUrl={isDestionationPage ? pageUri : null}
      imageUrl={destionation && destionation.socialImage && destionation.socialImage.sourceUrl}
      description={destionation && destionation.socialDescription}
      type='page'
      pageContext={pageContext}
    />
  }

  return (
    <div>
      { SeoObject }

      <Page pageContext={ pageContext } />
    </div>
  )
}

export default PreviewPage

