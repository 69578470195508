module.exports = () => {
    return `
      ... on WordPress_Post_Components_Contents_YoutubeVideo {
        fieldGroupName
        headline
        headlineColor
        subHeadline
        subHeadlineColor
        youtubeVideoId
        backgroundColor
      }
    `
}
