module.exports = () => {
  return `
    ... on WordPress_Post_Components_Contents_ImageLayouts {
      cutlineOne
      cutlineTwo
      fieldGroupName
      split
      imageTwo {
        title
        altText
        sourceUrl
        mediaDetails {
          height
          width
        }   
      }
      imageOne {
        title
        altText
        sourceUrl
        mediaDetails {
          height
          width
        }   
      }
    }
  `
}
