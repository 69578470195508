module.exports = () => {
  return `
    ... on WordPress_Post_Components_Contents_ArticleHero {
      fieldGroupName
      authors
      byline
      title
      heroImage {
        sourceUrl
        title
        altText
        mediaDetails {
          height
          width
        }
      }
      mobileHeroImage {
        sourceUrl
        title
        altText
        mediaDetails {
          height
          width
        }
      }
      socialHeroImage {
        sourceUrl
        title
        altText
        mediaDetails {
          height
          width
        }
      }
    }
  `
}
