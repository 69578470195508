module.exports = () => {
  return `
    ... on WordPress_Page_Components_Contents_Spacer {
      fieldGroupName
      spacer
      spacerBackground
      visibility      
    }
  `
}
