module.exports = () => {
  return `
    ... on WordPress_Post_Components_Contents_BrandLogo {
      fieldGroupName
      brandLogoImage {
        altText
        sourceUrl
        mediaDetails {
          height
          width
        }
      }
    }
  `;
};
